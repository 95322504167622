import React from 'react'
import styled from 'styled-components'
import stars from '../assets/images/five-stars-gartner-new.svg'
import Block from './Block'
import QuoteGridBlock from './G2QuoteGridBlock'

const Testimonials = () => {
  return (
    <TestimonialsContainer>
      <Block type="padding-large">
        <div className="layout-index">
          <h3>You’re in great company</h3>
        </div>
        <QuotesGrid>
          <QuoteGridBlock
            quote="“Swarmia offers visibility to different metrics for people in different roles. For individual contributors you get insights about your code, for teams you get information about how you are performing and how you are living according to your team agreements, and for management overall insights about how the whole development organization is doing.”"
            name="Verified User"
            title="IT Services"
            photo={stars}
            company="Mid-Market (50M-250M USD)"
          />
          <QuoteGridBlock
            quote="“Swarmia offers easy and flexible onboarding. The same flexibility is used across the whole software.”"
            name="Verified User"
            title="Media"
            photo={stars}
            company="Enterprise (1B-3B USD)"
          />
          <QuoteGridBlock
            quote="“Swarmia has been an absolute game-changer for me and my team on collaboration and code management. It has provided us with the much-needed clarity and insights into our work, helping us identify areas that require attention.”"
            name="Verified User"
            title="Software"
            photo={stars}
            company="SMB (<50M USD)"
          />
          <QuoteGridBlock
            quote="“We've been really happy with Swarmia. It gives the much needed visibility for developers to see their own development metrics.”"
            name="Verified User"
            title="Travel & Hospitality"
            photo={stars}
            company="SMB (<50M USD)"
          />

          <QuoteGridBlock
            quote="“I am exceptionally happy with the decision to bring in the use of Swarmia as part of the product team’s development tools. Swarmia has enabled the team to make appropriate changes to increase development speed, based on the data of our earlier performance.”"
            name="Verified User"
            title="Software"
            photo={stars}
            company="SMB (<50M USD)"
          />
          <QuoteGridBlock
            quote="“I can highly recommend Swarmia. The tool was really easy to deploy, and it provides a lot of good insights. Also, our developers love the Slack integration.”"
            name="Verified User"
            photo={stars}
            title="Software"
            company="SMB (<50M USD)"
          />
        </QuotesGrid>
      </Block>
    </TestimonialsContainer>
  )
}

export default Testimonials

const TestimonialsContainer = styled.div`
  h3 {
    text-align: center;
  }
`

const QuotesGrid = styled.div`
  -webkit-columns: 360px 3;
  -moz-columns: 360px 3;
  columns: 360px 3;
  -webkit-column-gap: 24px;
  -moz-column-gap: 24px;
  column-gap: 24px;
  margin: 0 auto;
  overflow: visible;
  display: block;
  -moz-column-fill: balance;
  column-fill: balance;
`
